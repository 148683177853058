export const formatMenuItemExtras = (extrasArray) => {
  // Create a Set to store unique extraCode values
  const uniqueExtraCodes = new Set();
  for (const obj of extrasArray) {
    // Create a unique identifier for each object
    const identifier = JSON.stringify({ extraCode: obj.extraCode, required: obj.required, specialty: obj.specialtyPizza, maxQty: obj.maxQtySelected , selectMultiple: obj.selectMultiple });
    
    // Add the identifier to the Set
    uniqueExtraCodes.add(identifier);
  }
  
  // Convert the Set back to an array of objects
  const unique = Array.from(uniqueExtraCodes).map(identifier => JSON.parse(identifier));
  
  // Create an array to store the result
  const resultArray = [];
  // Create a Map to store unique extraNames and their corresponding codes
  const uniqueExtraNamesMap = new Map();
  // Iterate through the list and add unique extraNames and their codes to the Map
  for (const obj of extrasArray) {
    if (!uniqueExtraNamesMap.has(obj.extraName)) {
      uniqueExtraNamesMap.set(obj.extraName, obj.extraCode);
    }
  }
  // Convert the Map to an array of objects
  for (const [name, code] of uniqueExtraNamesMap) {
    resultArray.push({ name, code });
  }

  const newArray = unique.map((code) => ({
    code: code,
    extras: resultArray.filter((item) => item.code === code.extraCode),
  }));

  for (var a = 0; a < newArray.length; a++) {
    for (var b = 0; b < newArray[a].extras.length; b++) {
      newArray[a].extras[b].options = [];
      for (var c = 0; c < extrasArray.length; c++) {
        if (newArray[a].extras[b].name === extrasArray[c].extraName) {
          newArray[a].extras[b].options.push(extrasArray[c]);
        }
      }
    }
  }

  return newArray;
};
