import React, { useContext } from "react";
import ApplicationContext from "../../ApplicationContext";
import axios from "axios";
import { APPLICATION, BASE_API } from "../../constants";
import {
  updateCartItemsAfterDeleteItem,
  updateCartAfterDeleteItem,
} from "../../utils/cart/UpdateCartAfterDeleteItem";
import { toast } from "react-toastify";
import { AiFillCloseCircle } from "react-icons/ai";

function CartItems(props) {
  const appContext = useContext(ApplicationContext);

  async function deleteItemFromCart(i) {
    props.setIsDisabled(true);
    setTimeout(() => {
      props.setIsDisabled(false);
    }, 1000);

    if (appContext.cartItems.length === 1) {
      props.setCartOpen(false);
    }

    var newPrice = 0;
    for (var a = 0; a < appContext.cartItems.length; a++) {
      if (appContext.cartItems[a].CartItemID !== i.CartItemID) {
        newPrice = newPrice + appContext.cartItems[a].ItemTotalPrice;
      }
    }

    try {
      await axios.delete(
        `${BASE_API}api/Cart/DeleteCartItem?cartitemId=` +
          i.CartItemID +
          "&deleteTotal=" +
          newPrice,
        {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        }
      );

      var cartItemToDelete = i;
      var updatedCart = updateCartAfterDeleteItem(appContext, newPrice);
      var updatedCartItems = updateCartItemsAfterDeleteItem(
        appContext,
        cartItemToDelete
      );
      var newAppContext = {
        userInfo: appContext.userInfo,
        cart: updatedCart,
        cartItems: updatedCartItems,
      };
      props.setAppContext(newAppContext);
      localStorage.setItem("appContext", JSON.stringify(newAppContext));
    } catch (error) {
      toast.error("We could not process your request. Please log in again.");
    }

    if(i.RewardCost > 0){
      try {
        var newPointsBalance = appContext.userInfo.rewardPoints + i.RewardCost;
        var userPointsData = {
          rewardPoints: newPointsBalance,
          userID: appContext.userInfo.userID,
        };
        await axios.put(`${BASE_API}UpdateCustomerRewardsPoints`, userPointsData, {
          headers: {
            Authorization: `Bearer ${appContext.userInfo.token}`,
            Application: APPLICATION,
          },
        });

        var newUserInfo = appContext.userInfo;
        newUserInfo.rewardPoints = parseFloat(newPointsBalance);

        newAppContext = {
          userInfo: newUserInfo,
          cart: updatedCart,
          cartItems: appContext.cartItems,
        };
        props.setAppContext(newAppContext);
        localStorage.setItem("appContext", JSON.stringify(newAppContext));
      } catch (error) {
        toast.error("We could not process your request. Please log in again.");
      }
    }
  }

  return (
    <div className="items_wrapper">
      {appContext.cartItems?.map((i, index) => (
        <>
          {i.RewardCost > 0 && <div className="reward_total">{i.RewardCost} Reward Points</div>}
          <div className="cartpopup_item" key={index}>
            <div className="left">
              <div className="item_container">
                <div className="item">
                  <div className="cartpopup_itemQuantity mx-1">
                    {i.ItemQuantity + "x "}
                  </div>
                  <div className="cartpopup_itemName">
                    <div>{i.ItemName}</div>
                    <div className="size mx-1">
                      {i.SizeCode && <>({i.SizeCode})</>}
                    </div>
                  </div>
                </div>
                {i.Extras.map((j) => (
                  <div className="cartpopup_itemExtra" key={j.MenuItemExtraID}>
                    {"+ " + j.ExtraName}
                    {j.PizzaHalfCode && <>{" - " + j.PizzaHalfCode}</>}
                  </div>
                ))}
                {i.ItemSpecialRequests ? (
                  <div className="cartpopup_itemRequests">
                    {"Requests: " + i.ItemSpecialRequests}
                  </div>
                ) : null}
              </div>

              <div className="prices_container">
                <div className="prices">
                  <div className="cartpopup_itemPrice">
                    {"$" + i.ItemPrice.toFixed(2)}
                  </div>

                  {i.Extras.map((j) => (
                    <div
                      className="cartpopup_extraPrice"
                      key={j.MenuItemExtraID}
                    >
                      {"$" + j.ExtraPrice.toFixed(2)}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="right">
              <div
                className="cartpopup_deleteItemButton"
                onClick={
                  !props.isDisabled ? () => deleteItemFromCart(i) : undefined
                }
              >
                <AiFillCloseCircle />
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
}

export default CartItems;
